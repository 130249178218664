#serviceContainer {
    padding: 0 4%;
}

#serviceContainer #headBook {
    background-color: #333;
    padding: 2% 5%;
    border-radius: 100px;
    color: white;
}

h2 {
    font-size: 1.2em;
}

#article h2 {
    color: #002861;
}

#serviceContainer #headBook h1 {
    color: #05AAC1;
    font-size: 2.9em;
}

#serviceContainer #headBook img {
    height: 300px;
    max-height: 300px;
}

#serviceContainer #headBook ion-button {
    margin: 5px;
    font-size: 1.1em;
    --background: #05AAC1;
    --border-color: #05AAC1;
}

#serviceContainer article {
    // padding: 0 5%;
    font-size: 1.1em;
    line-height: 160%;
}

#serviceContainer #article ion-col {
    padding: 0 5%;
}

#serviceContainer #article .bgWala {
    background-color: #05abc15c;
    border-radius: 30px;
    padding: 2% 5%;
}

#serviceContainer #article img {
    border-radius: 20px;
}

.gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    background-color: #f2eded;
    border-radius: 10px;
}

.bgWala h3 {
    color: #002861;
}

.gallery img {
    width: 30%;
    margin: 1.5%;
}

.gallery h2 {
    font-family: "Carter One", cursive;
    width: 100%;
    text-align: center;
}

#logosShow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 40px 0;
}

#logosShow img {
    height: 100px;
}

@media (max-width: 750px) {
    #logosShow img {
        height: 60px;
    }
    #serviceContainer {
        padding: 0 2%;
    }
    #serviceContainer #headBook {
        padding: 3%;
        border-radius: 10px;
    }
    #serviceContainer #container {
        padding: 0 4%;
    }
    #serviceContainer #headBook img {
        height: auto;
        width: 80%;
    }
    #serviceContainer article {
        font-size: 1.1em;
        line-height: 180%;
    }
    #serviceContainer #article ion-col {
        padding: 0 3%;
    }
    #serviceContainer #headBook h1 {
        color: #05AAC1;
        font-size: 3em;
    }
}