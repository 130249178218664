/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* AB Adeed */

@import 'swiper/css';
@import 'swiper/css/bundle';

/* Page */

@import '../src/css/page.scss';
* {
    font-family: 'Roboto Condensed', sans-serif;
}

.displayText {
    font-family: 'Carter One', cursive;
}

body {
    background-image: url('assets/images/bg.jpg');
}

#upperGap {
    margin-top: 76px;
}

#serviceContainer {
    font-size: 1.1em;
    line-height: 180%;
}

#logosShow img {
    border: 1px solid #e9e7e7;
    height: auto;
    max-width: 200px;
}

.marquee {
    width: 100%;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

.marquee p {
    display: inline-block;
    padding-left: 50%;
    animation: marquee 30s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

@media (max-width: 750px) {
    #upperGap {
        margin-top: 61px;
    }
}